<template>
  <div class="d-flex">
    <b-sidebar
      id="tags-sidebar-right"
      class="sidebar-add-tags"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '50vw' : '100vw'"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t('MaterialsPage.ManageTags') }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>
        <div class="separator"></div>
      </template>
      <template>
        <div
          class="h-100"
          v-if="manageTags.length === 0"
        >
          <div class="no-tags h-100">
            <EmptyBoxIcon class="empty-box" />
            <p class="title">{{ $t('MaterialsPage.NoTagTitle') }}</p>
            <p class="subtitle">
              {{ $t('MaterialsPage.NoTagSubtitle') }}
            </p>
            <b-button
              class="btn-new-tags"
              @click="addNewTag()"
            >
              <AddIcon class="add-icon" />
              {{ $t('MaterialsPage.NewTag') }}
            </b-button>
          </div>
        </div>
        <div v-else>
          <div class="tags-subtitle">
            {{ $t('MaterialsPage.TagsSubtitle') }}
          </div>
          <div
            v-for="(tag, index) in manageTags"
            :key="tag.name + '-' + index"
          >
            <b-col
              md="12"
              class="tag-input"
            >
              <b-form-group
                label=""
                :label-for="tag.name"
                :invalid-feedback="
                  (isToValidate
                    ? manageTags.filter((e) => e.name && e.name === tag.name).length > 1
                    : false) || flagEqualTagName
                    ? $t('MaterialsPage.EqualTagName')
                    : $t('RequiredField')
                "
              >
                <b-form-input
                  :id="tag.name"
                  :value="tag.name"
                  lazy-formatter
                  :formatter="
                    (e) => {
                      const hasEqualNames =
                        manageTags.filter((e) => e.name && e.name === tag.name).length > 1;
                      const hasEmptyTags = manageTags.find((e) => e.name === '');
                      if (e.trim() !== '' && !hasEqualNames && !hasEmptyTags) {
                        isToValidate = false;
                      }

                      return e;
                    }
                  "
                  @change="(e) => (tag.name = e)"
                  :state="verifyState(tag)"
                  :placeholder="$t('ResourceStatus.Placeholder')"
                />
              </b-form-group>
              <div
                class="delete-tag"
                @click="removeTag(index)"
              >
                <TrashIcon />
              </div>
            </b-col>
          </div>
          <b-button
            class="btn-add-tag"
            @click="addNewTag()"
          >
            <AddCircleIcon class="add-icon" />
            {{ $t('MaterialsPage.AddTag') }}
          </b-button>
        </div>
      </template>

      <template #footer>
        <div class="separator"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div :class="['d-flex', 'buttons']">
            <b-button
              :class="['btn-cancel', 'btn-footer']"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BButton, BCol, BSidebar } from 'bootstrap-vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import Tooltip from '@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue';

  export default {
    data() {
      return {
        isOpen: false,
        flagEqualTagName: false,
        isToValidate: false,
        hasRemoveATag: false,
        prefix: localStorage.getItem('prefix'),
        manageTags: []
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BCol,
      BSidebar,
      Tooltip,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      RouteIcon: () => import('@core/assets/icons/route-icon.svg'),
      OperationIcon: () => import('@core/assets/icons/operation-icon.svg'),
      MaterialIcon: () => import('@core/assets/icons/material-icon.svg'),
      AddIcon: () => import('@core/assets/icons/add-icon.svg'),
      AddCircleIcon: () => import('@core/assets/icons/add-circle.svg'),
      TrashIcon: () => import('@core/assets/icons/trash-icon.svg'),
      EmptyBoxIcon: () => import('@core/assets/icons/empty-box-icon.svg')
    },
    props: {
      device: {
        type: String,
        default: 'desktop'
      }
    },
    mounted() {
      this.UPDATE_MODAL_MANAGE_TAGS_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('materials', ['UPDATE_MODAL_MANAGE_TAGS_TOGGLE']),
      verifyState(tag) {
        const currentText = tag.name.trim();
        const isValid =
          currentText !== '' &&
          this.manageTags.filter((e) => e.name && e.name === currentText).length > 1 === false
            ? null
            : false;
        return this.isToValidate ? isValid : null;
      },
      closeModal() {
        this.UPDATE_MODAL_MANAGE_TAGS_TOGGLE(false);
      },
      removeTag(index) {
        this.hasRemoveATag = true;
        this.manageTags.splice(index, 1);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      addNewTag() {
        this.manageTags.push({ name: '' });
      },
      clearValidations() {
        this.flagEqualTagName = false;
      },
      fieldsValidate() {
        this.clearValidations();
        const allTagsValid =
          !this.manageTags.find((e) => e.name.trim() === '') && this.flagEqualTagName === false
            ? true
            : false;

        return allTagsValid;
      },
      async getTags() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http2.get('/api/myconfig/material/tag').then((response) => {
          this.manageTags = response.data;
          this.UPDATE_FLAG_SHOW_OVERLAY(false);
        });
      },
      onSave() {
        this.flagEqualTagName = false;
        this.isToValidate = true;
        const isValid = this.fieldsValidate();
        if (!isValid) return;

        if (this.hasRemoveATag) {
          this.$swal({
            title: this.$t('ResourceStatus.Attention'),
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: this.$t(
              'ProductionProgress.RemoveAppointmentConfirmationModal.Cancel'
            ),
            confirmButtonText: this.$t(
              'ProductionProgress.RemoveAppointmentConfirmationModal.Confirm'
            ),
            text: this.$t('MaterialsPage.ModalTagsText'),
            customClass: {
              container: 'swal-conection',
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.updateTags();
            }
          });
        } else {
          this.updateTags();
        }
      },
      async updateTags() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const payload = this.manageTags;
        await this.$http2
          .put('/api/myconfig/material/tag', payload)
          .then((response) => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('MaterialsPage.TagsUpdated'),
              'success'
            );
            this.$emit('updateTags');
            this.closeModal();
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('IntegrationPage.Error'), 'SmileIcon', e.message, 'danger');
            });
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      }
    },
    computed: {
      ...mapState('materials', {
        isModalManageTagsOpen: 'isModalManageTagsOpen'
      })
    },
    watch: {
      isModalManageTagsOpen(v) {
        this.isOpen = v;
        if (!v) {
          this.isToValidate = false;
          this.clearValidations();
          this.manageTags = [];
          this.hasRemoveATag = false;
        } else {
          this.getTags();
        }
      }
    }
  };
</script>

<style lang="scss">
  .sidebar-add-tags {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px;

        .no-tags {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          margin: 0 16px;

          .empty-box {
            width: 80px;
            height: 80px;
          }

          .title {
            padding-top: 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            color: #4c4541;
            margin: 0;
          }

          .subtitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #998f8a;
            padding: 4px 0 12px;
            margin: 0;
          }

          .btn-new-tags {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 8px 21px;
            line-height: 20px;
            letter-spacing: 0.175px;

            .add-icon {
              width: 14px;
              height: 14px;
              fill: #fff;
            }
          }

          @media (max-width: 480px) {
            .empty-box {
              width: 40px;
              height: 40px;
            }

            .title {
              font-size: 14px;
            }

            .subtitle {
              font-size: 12px;
              text-align: center;
              padding: 4px 48px 12px;
              line-height: 16px;
            }

            .btn-new-tags {
              padding: 3px 14px;
              color: #fff;
              font-size: 12px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.15px;

              .add-icon {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .tags-subtitle {
          color: #998f8a;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-bottom: 16px;

          @media (max-width: 480px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .tag-input {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          padding: 0;

          .form-group {
            width: 100%;
          }

          .delete-tag {
            display: flex;
            width: 28px;
            height: 28px;
            border-radius: 5px;
            cursor: pointer;
            padding: 8px;
            margin-bottom: 16px;

            &:hover {
              background-color: #ffede2 !important;
            }

            &:active {
              background-color: #ffdbc4 !important;
            }
          }

          &:has(.is-invalid) {
            .delete-tag {
              margin-bottom: 30.5px;
            }
          }
        }

        .btn-add-tag {
          display: flex;
          gap: 6px;
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid transparent !important;
          padding: 8px 21px;
          margin-top: 16px;

          &:hover {
            background-color: #ffede2 !important;
            box-shadow: none;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
