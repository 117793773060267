<template>
  <div class="d-flex">
    <b-sidebar
      id="route-sidebar-right"
      :class="['sidebar-add-edit-route', modalTitle.includes('Edit') ? 'is-edit' : '']"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '50vw' : '100vw'"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>
        <div class="separator"></div>
      </template>
      <template>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="
              flagNameEqual ? $t('MaterialsPage.EqualRouteName') : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.name"
              lazy-formatter
              :formatter="
                () => {
                  states.name = name !== '';
                  return name;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Description')"
            label-for="description"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.description"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="form-group"
        >
          <Multiselect
            label="Tags"
            :fieldPlaceholder="$t('Select')"
            :status="states.tags"
            :feedback="$t('RequiredField')"
            :datalist="tagsList"
            :selectedItems.sync="selectedTags"
            :notFoundMessage="$t('NoResultsFound')"
            :searchPlaceholder="$t('message.SearchPlaceholderDefault')"
          />
        </b-col>
        <b-col
          md="12"
          class="custom-switch-button"
        >
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="defaultRoute"
              :disabled="defaultDisabled"
              name="default-route-switch"
              switch
              class="check-button p-0"
            />
            <div class="text-allow">{{ $t('MaterialsPage.DefaultRoute') }}</div>
          </div>
        </b-col>
      </template>

      <template #footer>
        <div class="separator"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex buttons">
            <b-button
              :class="['btn-cancel', 'btn-footer']"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import {
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BSidebar,
    BCollapse,
    BFormTextarea,
    BFormCheckbox
  } from 'bootstrap-vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import Tooltip from '@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue';
  import Multiselect from 'components-code';

  export default {
    data() {
      return {
        flagNameEqual: false,
        isOpen: false,
        states: {
          name: null
        },
        name: '',
        description: '',
        selectedTags: [],
        defaultRoute: false,
        defaultDisabled: false,
        prefix: localStorage.getItem('prefix')
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BRow,
      BCol,
      BSidebar,
      BCollapse,
      Tooltip,
      BFormTextarea,
      Multiselect,
      BFormCheckbox,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      RouteIcon: () => import('@core/assets/icons/route-icon.svg'),
      OperationIcon: () => import('@core/assets/icons/operation-icon.svg'),
      MaterialIcon: () => import('@core/assets/icons/material-icon.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'desktop'
      },
      currentParentId: {
        type: Number,
        default: null
      },
      currentId: {
        type: Number,
        default: null
      },
      isCurrentDefaultRoute: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: ''
      },
      tagsList: {
        type: Array
      }
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('materials', ['UPDATE_MODAL_ROUTE_TOGGLE']),
      closeModal() {
        this.UPDATE_MODAL_ROUTE_TOGGLE(false);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      clearFields() {
        this.name = '';
        this.description = '';
        this.selectedTags = [];
        this.defaultRoute = false;
        this.defaultDisabled = false;
      },
      clearValidations() {
        this.states.name = null;
      },
      fieldsValidate() {
        this.clearValidations();

        const validName = (this.states.name =
          this.name && this.flagNameEqual === false ? true : false);
        return validName;
      },
      async getInfos() {
        if (this.modalTitle.includes('Edit')) {
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          await this.$http2
            .get(`/api/myconfig/material/route/${this.currentId}`)
            .then((response) => {
              this.name = response.data.data.name;
              this.description = response.data.data.description;
              this.selectedTags = response.data.data.tags;
              this.defaultRoute = response.data.data.default;
              if (response.data.data.default) this.defaultDisabled = true;
              this.industrialParameters = response.data.data.industrialParameters;
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      },
      getMaterialName(list) {
        if (!list || list.length === 0) {
          return null;
        }

        for (const item of list) {
          if (item.id === this.currentParentId) {
            return item.name;
          }

          if (!item.isMaterials && item.child && item.child.length > 0) {
            const foundItem = this.getMaterialName(item.child);
            if (foundItem) {
              return foundItem;
            }
          }
        }
      },
      onSave() {
        this.flagNameEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        this.verifyDefaultRoute();
      },
      verifyDefaultRoute() {
        if (!this.defaultRoute || this.isCurrentDefaultRoute) return this.addingAndEditingRoutes();

        this.$http2
          .get(`api/myconfig/material/${this.currentParentId}/check-default-route`)
          .then((response) => {
            if (response.data.data.hasDefaultRoute) {
              this.modalChangeDefaultRoute();
            } else {
              this.addingAndEditingRoutes();
            }
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('IntegrationPage.Error'), 'SmileIcon', e.message, 'danger');
            });
          });
      },
      modalChangeDefaultRoute() {
        this.$swal({
          title: this.$t('ResourceStatus.Attention'),
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: this.$t('ProfilesPage.CancelButtonText'),
          confirmButtonText: this.$t('IntegrationPage.Change'),
          html: `<div>${this.$t('MaterialsPage.ModalAttentionText1')} <b>${this.getMaterialName(
            this.dragList
          )}</b>. ${this.$t('MaterialsPage.ModalAttentionText2')}</div>`,
          customClass: {
            container: 'swal-conection',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.defaultRoute = true;
            this.addingAndEditingRoutes();
          }
          if (result.isDismissed) {
            this.defaultRoute = false;
          }
        });
      },
      addingAndEditingRoutes() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        if (!this.modalTitle.includes('Edit')) {
          const payload = {
            name: this.name,
            description: this.description,
            tags: this.selectedTags.map((tag) => ({ tagId: tag })),
            default: this.defaultRoute,
            materialId: this.currentId
          };
          this.$http2
            .post(`/api/myconfig/material/route`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('MaterialsPage.RouteCreated'),
                'success'
              );
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.id === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        } else {
          const payload = {
            name: this.name,
            description: this.description,
            tags: this.selectedTags,
            defaultRoute: this.defaultRoute
          };

          this.$http2
            .put(`/api/myconfig/material/route/${this.currentId}`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.defaultRoute
                  ? this.$t('MaterialsPage.DefaultRouteChanged')
                  : this.$t('MaterialsPage.RouteEdited'),
                'success'
              );
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.id === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      }
    },
    computed: {
      ...mapState('materials', {
        isModalRouteOpen: 'isModalRouteOpen',
        dragList: 'dragList'
      })
    },
    watch: {
      isModalRouteOpen(v) {
        this.isOpen = v;
        this.clearValidations();
        this.clearFields();
        if (v) {
          this.getInfos();
          const materialChildren = this.dragList.find(
            (e) => e.id === this.currentParentId && !e.isMaterials
          ).child;
          if (materialChildren.length === 0) {
            this.defaultRoute = true;
            this.defaultDisabled = true;
          }
        }
      }
    }
  };
</script>

<style lang="scss">
  .swal-conection {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
        }
      }

      .swal2-content {
        padding: 20px 16px;

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }
  .sidebar-add-edit-route {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    &.is-edit .b-sidebar {
      box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
      border-left: none;
    }

    .b-sidebar {
      box-shadow: none;
      border-left: 1px solid #cfc4be;
      transition: all 0.5s ease-out;
      height: 100% !important;

      .b-sidebar-body {
        padding: 16px 0;

        .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
          background-color: #974900;
        }

        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          label {
            color: #4c4541;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }

          .custom-switch-button {
            .custom-control-label {
              margin: 0;
              line-height: 20px;
            }

            .custom-control-input ~ .custom-control-label::before {
              background-color: #e2e2e2;
              height: 20px;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
              background-color: #974900;
            }

            .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
              background-color: #e2e2e2;
              border-color: #e2e2e2;
            }

            .text-allow {
              color: #4c4541;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
