<template>
  <div>
    <b-sidebar
      id="link-materials-sidebar-right"
      class="link-materials-sidebar-link-modal"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <div v-if="!isEdit">
          <div class="text-block">
            <div class="title">{{ $t('MaterialsPage.LinkMaterial') }}</div>
            <div class="subtitle">
              {{ $t('MaterialsPage.MaterialsSubTitleLinkModal') }}
            </div>
          </div>
          <div class="search-block">
            <div class="label-title">{{ $t('Menu.Items.Materials') }}</div>
            <div class="search-input">
              <div class="div-custom-input input__materials">
                <div class="icon-block">
                  <SearchIcon class="search-icon" />
                </div>
                <b-form-tags
                  class="input-search-link-materials"
                  ref="formTagLinkMaterials"
                  v-model="searchFilter"
                  duplicate-tag-text=""
                  :placeholder="searchFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
                  @input="updateFilter(searchFilter)"
                  remove-on-delete
                  add-on-change
                />
              </div>
            </div>
          </div>
          <div class="action-block">
            <div class="select-all">
              <div
                :class="['checkbox-material', allMaterialsChecked ? 'checked' : '']"
                @click="checkAllMaterials()"
              >
                <span class="icon-checkbox"><CheckIcon /></span>
              </div>
              <span class="text">
                {{ $t('SelectAll') }}
              </span>
            </div>
          </div>
          <div class="materials-block">
            <div
              v-if="filteredList.length === 0"
              class="block-no-results"
            >
              <div class="no-results">
                <SearchIcon />
                <p class="text">{{ $t('NoResultsFound') }}</p>
              </div>
            </div>
            <div v-else>
              <div
                v-for="material in filteredList"
                :key="material.id"
              >
                <div class="material-info-block">
                  <div class="material">
                    <div
                      class="check-material"
                      v-if="forceRenderLinkMaterials"
                    >
                      <div
                        :class="[
                          'checkbox-material __no-action-default',
                          material.checked ? 'checked' : ''
                        ]"
                        @click="toggleCheckbox(material)"
                      >
                        <span class="icon-checkbox __no-action-default"><CheckIcon /></span>
                      </div>
                    </div>

                    <div class="text-block">
                      <div class="materials-name">{{ material.name }}</div>
                      <div class="materials-code">{{ material.code }}</div>
                    </div>
                  </div>
                  <div
                    v-if="material.checked"
                    class="material-options"
                  >
                    <b-form-group
                      :label="$t('MaterialsPage.RequiredQuantity')"
                      label-for="quantity"
                      :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        id="quantity"
                        :value="material.quantityRequired"
                        placeholder="0,00"
                        type="text"
                        :state="
                          isToValidade
                            ? material.checked && Number(material.quantityRequired) === 0
                              ? false
                              : null
                            : null
                        "
                        @input.native="
                          (e) => {
                            const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                            material.quantityRequired = maskApplied;
                            e.target.value = maskApplied;
                          }
                        "
                      />
                    </b-form-group>
                    <div class="custom-switch-button">
                      <div class="d-flex align-items-center">
                        <b-form-checkbox
                          v-model="material.required"
                          name="default-route-switch"
                          switch
                          class="check-button p-0"
                          @change="(e) => (material.required = e)"
                        />
                        <div class="text-allow">{{ $t('Required') }}</div>
                        <InfoIcon
                          :id="`${material.name}-${material.id}`"
                          class="info-icon"
                        />
                      </div>
                    </div>
                    <Tooltip
                      :target="`${material.name}-${material.id}`"
                      :text="$t('MaterialsPage.RequiredText')"
                      placement="top"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-form-group
            :label="$t('MaterialsPage.RequiredQuantity')"
            label-for="quantity"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              id="quantity"
              :value="quantityRequired"
              :placeholder="$t('ResourceStatus.Placeholder')"
              type="text"
              step="0.01"
              :state="state.quantityRequired"
              @input.native="
                (e) => {
                  const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                  state.quantityRequired = Number(maskApplied) === 0 ? false : null;
                  quantityRequired = maskApplied;
                  e.target.value = maskApplied;
                }
              "
            />
          </b-form-group>
          <div class="custom-switch-button">
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="required"
                name="default-route-switch"
                switch
                class="check-button p-0"
              />
              <div class="text-allow">{{ $t('Required') }}</div>
              <InfoIcon
                id="material-required-tooltip"
                class="info-icon"
              />
            </div>
          </div>
          <Tooltip
            target="material-required-tooltip"
            :text="$t('MaterialsPage.RequiredText')"
            placement="top"
          />
        </div>
      </template>
      <template #footer>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex buttons">
            <b-button
              class="btn btn-cancel btn-footer"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              v-if="isEdit"
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
            <b-button
              v-else
              class="btn btn-save btn-footer"
              @click="onLinkMaterials()"
            >
              {{ $t('IntegrationPage.Link') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import {
    BSidebar,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormTags
  } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import Popover from '@core/layouts/layout-horizontal/components/Menu/Popover.vue';
  import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import Tooltip from '@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue';

  export default {
    components: {
      BSidebar,
      BCol,
      BButton,
      BFormInput,
      BFormTags,
      AvatarPlaceholder,
      ToastificationContent,
      Popover,
      BFormCheckbox,
      BFormGroup,
      Tooltip,
      ArrowDown: () => import('@core/assets/icons/arrow-down-orange.svg'),
      CheckIcon: () => import('@core/assets/icons/check-icon.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg'),
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      InfoIcon: () => import('@core/assets/icons/info-icon.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'desktop'
      },
      currentParentId: {
        type: Number,
        default: null
      },
      currentId: {
        type: Number,
        default: null
      },
      prefix: {
        type: String,
        default: ''
      },
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        forceRenderLinkMaterials: true,
        allMaterialsChecked: false,
        isOpen: false,
        isToValidade: false,
        searchFilter: [],
        filteredList: [],
        state: {
          quantityRequired: null
        },
        required: false,
        quantityRequired: ''
      };
    },
    mounted() {
      this.UPDATE_MODAL_LINK_MATERIALS_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('materials', [
        'UPDATE_MODAL_LINK_MATERIALS_TOGGLE',
        'UPDATE_MATERIALS_TO_LINK_LIST'
      ]),
      activeCarousel() {
        const vm = this;
        $(function () {
          document
            .getElementsByClassName('input-search-link-materials')[0]
            .setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.b-form-tags-list');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);

          $('.b-form-tags-input').blur((event) => {
            let input = vm.$refs.formTagLinkMaterials;
            input.addTag(event.target.value);
          });
        });
      },
      closeModal() {
        this.UPDATE_MODAL_LINK_MATERIALS_TOGGLE(false);
      },
      toggleCheckbox(elem) {
        if (!elem.checked) {
          elem['checked'] = true;
        } else {
          elem['checked'] = false;
          elem['quantityRequired'] = '';
          elem['required'] = false;
          this.allMaterialsChecked = false;
        }
        this.verifyAllChecked();
        this.forceRenderLinkMaterialsList();
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      unCheckAllMaterials() {
        this.materialsToLink.map((e) => {
          e['checked'] = false;
        });
        this.allMaterialsChecked = false;
        this.isToValidade = false;
      },
      updateFilter(e) {
        if (e.length === 0) {
          this.filteredList = this.materialsToLink;
          return;
        }
        const arr = this.materialsToLink;
        const filterValue = e.map((value) => value.toLowerCase());
        const regex = new RegExp(filterValue.join('|'), 'i');

        const filteredArray = arr.filter((item) => {
          const fullName = item.name.toLowerCase();
          return filterValue.some((filter) => regex.test(fullName) || regex.test(item.code));
        });
        this.filteredList = filteredArray;
      },
      checkAllMaterials() {
        this.filteredList.map((e) => {
          if (this.allMaterialsChecked) {
            e['checked'] = false;
          } else {
            e['checked'] = true;
          }
        });
        this.verifyAllChecked();
        this.forceRenderLinkMaterialsList();
      },
      verifyAllChecked() {
        const hasUnChecked = this.filteredList.find((e) => !e.checked);
        this.allMaterialsChecked = hasUnChecked ? false : true;
      },
      clearValidations() {
        this.state.quantityRequired = null;
      },
      fieldsValidate() {
        if (!this.isEdit) {
          const checkedMaterials = this.filteredList.filter((e) => e.checked);
          const emptyFields = checkedMaterials.filter((e) => Number(e.quantityRequired) === 0);
          return emptyFields.length !== 0 ? false : true;
        } else {
          const validField = (this.state.quantityRequired = Number(this.quantityRequired) !== 0);
          return validField;
        }
      },
      forceRenderLinkMaterialsList() {
        this.forceRenderLinkMaterials = false;
        this.$nextTick().then(() => {
          this.forceRenderLinkMaterials = true;
        });
      },
      async getMaterialsToLink() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        if (this.isEdit) {
          await this.$http2
            .get(`api/myconfig/material/operation/${this.currentParentId}/${this.currentId}`)
            .then((response) => {
              this.required = response.data.data.required;
              this.quantityRequired = response.data.data.quantityRequired;
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            })
            .catch((error) => console.error(error));
        } else {
          await this.$http2
            .get(`api/myconfig/material/${this.currentId}/add-materials`)
            .then((response) => {
              const updatedMaterials = response.data.data.map((item) => ({
                ...item,
                quantityRequired: ''
              }));

              this.UPDATE_MATERIALS_TO_LINK_LIST(updatedMaterials);
              this.filteredList = this.materialsToLink;
              this.forceRenderLinkMaterialsList();
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            })
            .catch((error) => console.error(error));
        }
      },
      async onLinkMaterials() {
        this.isToValidade = true;
        const hasChecked = this.filteredList.find((e) => e.checked);
        const isValid = this.fieldsValidate();

        if (!hasChecked || !isValid) return;

        this.isToValidade = false;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const checkedMaterials = this.materialsToLink.filter((e) => !!e.checked);

        const payload = checkedMaterials.map((e) => {
          return {
            id: e.id,
            quantityRequired: Number(e.quantityRequired),
            required: e.required ? e.required : false
          };
        });
        await this.$http2
          .post(`/api/myconfig/material/operation/${this.currentId}/add-material`, payload)
          .then((response) => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('MaterialsPage.RelatedMaterials'),
              'success'
            );
            this.closeModal();
            this.$emit('updateList');
          })
          .catch((error) => {
            if (error.response.data?.errors?.length) {
              error.response.data.errors.map((e) => {
                this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
              });
            } else {
              this.showToast(this.$t('Error'), 'FrownIcon', error.response.data.message, 'danger');
            }
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      async onSave() {
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        const payload = {
          materialId: this.currentId,
          quantityRequired: this.quantityRequired,
          required: this.required
        };

        await this.$http2
          .put(`/api/myconfig/material/operation/${this.currentParentId}/update-material`, payload)
          .then(() => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('MaterialsPage.MaterialEdited'),
              'success'
            );
            this.closeModal();
            this.$emit('updateList');
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('IntegrationPage.Error'), 'SmileIcon', e.message, 'danger');
            });
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      }
    },
    computed: {
      ...mapState('materials', {
        isModalLinkMaterialsOpen: 'isModalLinkMaterialsOpen',
        materialsToLink: 'materialsToLink'
      })
    },
    watch: {
      isModalLinkMaterialsOpen(v) {
        this.isOpen = v;
        if (v) {
          this.getMaterialsToLink();
          !this.isEdit && this.activeCarousel();
        } else {
          this.isToValidade = false;
          this.clearValidations();
          this.required = false;
          this.quantityRequired = '';
          this.allMaterialsChecked = false;
          this.UPDATE_MATERIALS_TO_LINK_LIST([]);
        }
      },
      searchFilter() {
        this.unCheckAllMaterials();
      }
    }
  };
</script>
<style lang="scss">
  .link-materials-sidebar-link-modal {
    .b-sidebar.b-sidebar-right {
      transition: all 0.5s ease-out;
      @media (max-width: 814px) {
        width: 50vw !important;
      }
      @media (max-width: 480px) {
        width: 100vw !important;
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .b-sidebar {
      height: 100% !important;
      box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

      .b-sidebar-header {
        display: contents;

        .header-sidebar {
          gap: 8px;
          padding: 16px;

          .sidebar-title {
            display: flex;
            align-items: center;
            color: #4c4541;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;

            @media (max-width: 480px) {
              font-size: 16px;
            }
          }
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          min-width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        .separator {
          width: 100%;
          height: 1px;
          background: #cfc4be;
        }
      }

      .b-sidebar-body {
        padding: 16px;
        overflow: hidden;

        .text-block {
          .title {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            padding-bottom: 8px;
          }

          .subtitle {
            color: #998f8a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 16px;
          }
        }

        .search-block {
          .search-input {
            display: flex;
            align-items: center;
            padding: 16px 0 8px;

            .div-custom-input {
              display: flex;
              align-items: center;
              border-radius: 4px;
              width: 100%;
              overflow: hidden;
              border: 1px solid #cfc4be;
              .icon-block {
                display: flex;
                align-items: center;
                padding-left: 14px;
                height: 14px;
                .search-icon {
                  width: 14px;
                  height: 14px;
                  fill: #cfc4be;
                }
              }
              .input-search-link-materials {
                width: 100% !important;
                border: none;
                overflow: hidden;

                &.focus {
                  box-shadow: none;
                }
                &.b-form-tags {
                  padding: 0 !important;
                  display: flex;
                  align-items: center !important;
                  .b-form-tags-list {
                    width: 100% !important;
                    margin-left: 14px !important;
                    margin-top: 0;
                    height: 100% !important;
                    flex-wrap: nowrap !important;
                    display: flex !important;
                    align-items: center !important;
                    overflow: scroll;
                    overflow-y: hidden;
                    cursor: grabbing;
                    cursor: -webkit-grabbing;

                    &::-webkit-scrollbar {
                      display: none !important;
                    }
                    .b-form-tags-field {
                      margin: 9px 0;
                    }
                  }
                }

                .b-form-tags-button {
                  display: none;
                }

                .b-form-tag {
                  background-color: #974900;
                  margin-right: 0.6rem;
                  padding: 2px 8px !important;
                  font-size: 13px;
                }
              }
            }
          }
          .label-title {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            padding-bottom: 8px;
          }
        }

        .action-block {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          .select-all {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 16px;

            .text {
              color: #4c4541;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }

          .function-filter .filter {
            color: #974900;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.15px;
          }

          .function-filter {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #4c4541;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            .filter {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 4px 10px;
              border-radius: 5px;
              cursor: pointer;

              .arrow-down {
                width: 12px;
                height: 12px;
              }

              &:hover {
                background: #ffede2;
              }

              &:active {
                background: #ffdbc4;
              }
            }
          }

          @media (max-width: 480px) {
            flex-wrap: wrap;
          }
        }

        .materials-block {
          padding-bottom: 16px;
          overflow: auto;
          height: calc(100vh - 395px);

          &:has(.block-no-results) {
            overflow-y: hidden;
          }

          .block-no-results {
            height: 100%;
            padding: 16px 0;
            .no-results {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 16px;
              border-radius: 6px;
              border: 1px solid #cfc4be;

              svg {
                width: 16px;
                height: 16px;
                fill: #998f8a;
              }

              .text {
                margin: 0;
                color: #998f8a;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
              }

              @media (max-width: 480px) {
                svg {
                  width: 12px;
                  height: 12px;
                }
                .text {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }
          }

          .material-info-block {
            padding: 7.5px 16px;
            overflow: hidden;
            border-bottom: 1px solid #eee;
            background: #fff;

            .material {
              display: flex;
              align-items: center;
              gap: 8px;
            }

            .check-material {
              display: flex;

              .checkbox-material {
                margin: 0;
              }
            }

            .text-block {
              overflow: hidden;
              .materials-name {
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .materials-code {
                color: #998f8a;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }

            &:has(.checked) {
              background-color: #fafafa;
            }
          }

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
          }
        }

        .custom-switch-button {
          .custom-control-label {
            margin: 0;
            line-height: 20px;
          }

          .custom-control-input ~ .custom-control-label::before {
            background-color: #e2e2e2;
            height: 20px;
          }

          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #974900;
          }

          .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            background-color: #e2e2e2;
            border-color: #e2e2e2;
          }

          .text-allow {
            color: #4c4541;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding-right: 8px;
          }

          .info-icon {
            cursor: pointer;
            width: 16px;
            height: 16px;
            fill: #998f8a;
          }
        }

        .checkbox-material {
          display: flex;
          background-color: #fff;
          border: 1px solid #cfc4be;
          border-radius: 3px;
          cursor: pointer;
          width: 18px;
          height: 18px;

          .icon-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
            svg {
              width: 12px;
              height: 12px;
            }
          }
          &.checked {
            background-color: #974900;
            display: block;
          }
        }

        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          label {
            color: #4c4541;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }

          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 9px;
        }
      }
    }

    .tooltip-inner {
      text-align: left !important;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      border-top: 1px solid #cfc4be;
      background: #fff;

      @media (max-width: 480px) {
        .buttons {
          width: 100%;

          .btn {
            width: 100%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-reset {
          background-color: transparent !important;
          color: #974900 !important;
          font-size: 12px !important;
          border: 1px solid transparent !important;
          padding: 4px 14px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          margin-right: 8px;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
