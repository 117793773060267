var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('b-sidebar',{staticClass:"sidebar-duplicate-material",attrs:{"id":"duplicate-material-sidebar-right","right":"","bg-variant":"white","width":_vm.device === 'desktop' ? '407px' : _vm.device === 'tablet' ? '60vw' : '100vw'},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-sidebar d-flex justify-content-between"},[_c('span',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.modalTitle))+" ")]),_c('span',{staticClass:"close-icon",on:{"click":function($event){return _vm.closeModal()}}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"separator"})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"separator"}),_c('b-col',{staticClass:"footer-sidebar",attrs:{"md":"12"}},[_c('div',{class:['d-flex', 'buttons']},[_c('b-button',{class:['btn-cancel', 'btn-footer'],on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t('RefuseStatus.Cancel'))+" ")]),_c('b-button',{staticClass:"btn-save btn-footer",on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.$t('RefuseStatus.Save'))+" ")])],1)])]},proxy:true}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DepartmentPage.Name'),"label-for":"name","invalid-feedback":_vm.flagNameEqual
              ? _vm.currentLevel === 1
                ? _vm.$t('MaterialsPage.EqualMaterialName')
                : _vm.currentLevel === 2
                ? _vm.$t('MaterialsPage.EqualRouteName')
                : _vm.$t('MaterialsPage.EqualOperationName')
              : _vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"name","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.name,"lazy-formatter":"","formatter":function () {
                _vm.states.name = _vm.name !== '';
                return _vm.name;
              }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),(_vm.currentLevel === 1)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('MaterialsPage.Code'),"label-for":"code","invalid-feedback":_vm.flagCodeEqual ? _vm.$t('MaterialsPage.EqualMaterialCode') : _vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"code","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.code},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1):_vm._e(),(_vm.currentLevel === 3)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('MaterialsPage.Number'),"label-for":"number","invalid-feedback":_vm.flagNumberEqual ? _vm.$t('MaterialsPage.EqualMaterialNumber') : _vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"number","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.number,"lazy-formatter":"","formatter":function () {
                _vm.states.number = _vm.number !== null || null !== 0;
                return _vm.number;
              },"type":"number"},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"linked-items"},[_c('span',{staticClass:"linked-items-text"},[_vm._v(_vm._s(_vm.$t('ProfilesPage.LinkedItems'))+":")]),_c('div',{staticClass:"icons-modal"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentLevel === 1),expression:"currentLevel === 1"}],staticClass:"icon-modal",attrs:{"id":"route-info"}},[_c('RouteIcon',{staticClass:"icon-duplicate"}),_c('span',[_vm._v(_vm._s(_vm.materialSelected.routes))])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentLevel !== 3),expression:"currentLevel !== 3"}],staticClass:"icon-modal",attrs:{"id":"operation-info"}},[_c('OperationIcon',{staticClass:"icon-duplicate"}),_c('span',[_vm._v(_vm._s(_vm.materialSelected.operations))])],1),_c('div',{staticClass:"icon-modal",attrs:{"id":"materials-info"}},[_c('MaterialIcon',{staticClass:"icon-duplicate"}),_c('span',[_vm._v(_vm._s(_vm.materialSelected.materials))])],1),_c('Tooltip',{attrs:{"target":"route-info","placement":"top","text":_vm.$t('MaterialsPage.Route')}}),_c('Tooltip',{attrs:{"target":"operation-info","placement":"top","text":_vm.$t('MaterialsPage.Operations')}}),_c('Tooltip',{attrs:{"target":"materials-info","placement":"top","text":_vm.$t('MaterialsPage.MaterialsUsed')}})],1)])])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }